.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
	min-height: auto;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cal-events-num {
  margin-top: 20px;
}
.cal-month-day {
  position: relative;
  display: block;
  width: 100%;
}

.cal-month-day .cal-events-num {
  margin-left: 10px;
  margin-top: 18px;
}

#cal-week-box {
  position: absolute;
  width: 70px;
  left: -71px;
  top: -1px;
  padding: 8px 5px;
  cursor: pointer;
}

.cal-day-tick {
  position: absolute;
  right: 50%;
  bottom: -21px;
  padding: 0px 5px;
  cursor: pointer;
  z-index: 5;
  text-align: center;
  width: 26px;
  margin-right: -17px;
}
.cal-year-box #cal-day-tick {
  margin-right: -7px;
}

.cal-slide-box {
  position: relative;
}

.cal-slide-tick {
  position: absolute;
  width: 16px;
  margin-left: -7px;
  height: 9px;
  top: -1px;
  z-index: 1;
}
.cal-slide-tick.tick-month1 {
  left: 12.5%;
}
.cal-slide-tick.tick-month2 {
  left: 37.5%;
}
.cal-slide-tick.tick-month3 {
  left: 62.5%;
}
.cal-slide-tick.tick-month4 {
  left: 87.5%;
}

.cal-slide-tick.tick-day1 {
  left: 7.14285714285715%;
}
.cal-slide-tick.tick-day2 {
  left: 21.42857142857143%;
}
.cal-slide-tick.tick-day3 {
  left: 35.71428571428572%;
}
.cal-slide-tick.tick-day4 {
  left: 50%;
}
.cal-slide-tick.tick-day5 {
  left: 64.2857142857143%;
}
.cal-slide-tick.tick-day6 {
  left: 78.57142857142859%;
}
.cal-slide-tick.tick-day7 {
  left: 92.85714285714285%;
}
.events-list {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.cal-slide-content ul.unstyled {
  margin-bottom: 0;
}
.cal-slide-content ul li.dragging-active .event-item {
  color: black;
}
