.cal-row-head [class*="cal-cell"]:first-child,
.cal-row-head [class*="cal-cell"] {
  font-weight: bolder;
  text-align: center;
  border: 0px solid;
  padding: 5px 0;
}
.cal-row-head [class*="cal-cell"] small {
  font-weight: normal;
}
.cal-year-box .row:hover,
.cal-row-fluid:hover {
  background-color: @rowHover;
}
.cal-month-day {
  height: @rowHeightMonth;
}
[class*="cal-cell"]:hover, .cell-focus, [class*="cal-cell"] .drop-active, .cal-cell.drop-active {
  background-color: @dayHover;
}
.cal-year-box [class*="span"],
.cal-month-box [class*="cal-cell"] {
  min-height: @rowHeightMonth;
  border-right: @borderSizevert @borderStyle @borderColor;
  position: relative;
}
.cal-year-box [class*="span"]{
  min-height: @rowHeightYear;
}
.cal-year-box .row [class*="col-"]:last-child,
.cal-month-box .cal-row [class*="cal-cell"]:last-child {
  border-right: 0px;
}
.cal-year-box .row,
.cal-month-box .cal-row-fluid {
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  margin-left: 0px;
  margin-right: 0px;
}
.cal-year-box .row:last-child,
.cal-month-box .cal-row-fluid:last-child {
  border-bottom: 0px;
}
.cal-month-box,
.cal-year-box,
.cal-week-box {
  border-top: @borderSizehoriz @borderStyle @borderColor;
  border-bottom: @borderSizehoriz @borderStyle @borderColor;
  border-right: @borderSizevert @borderStyle @borderColor;
  border-left: @borderSizevert @borderStyle @borderColor;
  border-radius: 2px;
}
.cal-month-box {
  border-right: 0px;
  border-bottom: 0px;
}
span[data-cal-date] {
  font-size: 1.2em;
  font-weight: normal;
  opacity: 0.5;
  transition: all .3s ease-in-out;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  margin-top: 15px;
  margin-right: 15px;
}
span[data-cal-date]:hover {
  opacity: 1;
}

.cal-day-outmonth span[data-cal-date] {
  opacity: 0.1;
  cursor: default;
}

.cal-day-today {
  background-color: #e8fde7;
}

.cal-day-today span[data-cal-date] {
  color: darkgreen;
}
.cal-month-box .cal-day-today span[data-cal-date] {
  font-size: 1.9em;
}
.cal-day-holiday span[data-cal-date] {
  color: #800080;
}

.cal-day-weekend span[data-cal-date] {
  color: darkred;
}

#cal-week-box {
  border: @borderSize @borderStyle @borderColor;
  border-right: 0px;
  border-radius: 5px 0 0 5px;
  background-color: @rowHover;
  text-align: right;
}

.cal-week-box .cal-row-head {
  border-bottom: 1px solid #e1e1e1;
}

.cal-day-tick {
  border: @borderSize @borderStyle @borderColor;
  border-top: 0px solid;
  border-radius: 0 0 5px 5px;
  background-color: @dayHover;
  text-align: center;

  .fa {
    display: none;
  }

}

.cal-slide-box {
  border-top: 0px solid #8c8c8c;
}

.cal-slide-content {
  padding: 20px;
  color: #ffffff;
  background-color: #555555;
  -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
}

.cal-slide-content a.event-item {
  color: #ffffff;
  font-weight: normal;
}

a.event-item-edit, a.event-item-delete {
  padding-left: 5px;
}

.cal-year-box .cal-slide-content a.event-item,
.cal-year-box a.event-item-edit,
.cal-year-box a.event-item-delete {
  position: relative;
  top: -3px;
}

.events-list {
  max-height: @rowHeightMonth  - 53px;
  padding-left: 5px;
}
.cal-column {
  border-left: @borderSize @borderStyle @borderColor;
}
a.cal-event-week {
  text-decoration: none;
  color: #151515;
}
.badge-important {
	background-color:#b94a48;
}

.pointer {
  cursor:pointer;
}

.cal-year-box:last-child {
  border-bottom: 0px;
}

.cal-context {
  width: 100%;
}

@media (max-width: 991px) {

  .cal-year-box [class*="span"]:nth-child(2) {
    border-right: 0px;
  }

  .cal-year-box [class*="span"]:nth-child(1), .cal-year-box [class*="span"]:nth-child(2) {
    border-bottom: 1px solid #e1e1e1;
  }

}
